import React from 'react'
import Logo from "../../assets/images/logo.png"
import { FaFacebook } from "react-icons/fa6";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { FiLinkedin } from "react-icons/fi";
import { AiFillInstagram } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { IoLocationOutline } from "react-icons/io5";
import { Box, Button, Flex, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'; 
export const Footer = () => {
  return (
    <Box
      bg="black"
      h={{ base: "auto", md: "50vh" }}
      p={{ base: "10", md: "14" }}
    >
      <Flex justifyContent={"center"} alignItems={"center"} gap="4">
        <Image w="10" h={{ base: "10", md: "10" }} src={Logo} />
        <Text color={"white"} fontWeight={"medium"}>
          Flashride
        </Text>
      </Flex>

      <SimpleGrid
        columns={{ base: "3", md: "6" }}
        mt="7"
        fontSize={"sm"}
        justifyContent={"center"}
        gap="10"
        color="gray.400"
        alignItems={"center"}
      >
        <Link to="/home">
          <Text>Home</Text>
        </Link>
        <Link to="/about">
          <Text>About Us</Text>
        </Link>
        <Link to="/privacy-policy">
          <Text>Privacy Policy</Text>
        </Link>
        <Link to="/terms-of-service">
          <Text>Terms of Service</Text>
        </Link>
      </SimpleGrid>
      <Flex justifyContent={"center"} gap="10" mt="14">
        <FaFacebookF color="white" />
        <FaTwitter color="white" />
        <FaInstagram color="white" />
        <FaYoutube color="white" />
      </Flex>
      <Flex justifyContent={"center"}>
        <Text
          fontSize={"xs"}
          position={"absolute"}
          bottom={"5"}
          color="gray.400"
        >
          © 2023 Flashride. All rights reserved
        </Text>
      </Flex>
    </Box>
  );
}
