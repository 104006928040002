import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import AboutSvg from "../../assets/images/About Us.png";
import Mission from "../../assets/images/Frame 441063.png";
import Team from "../../assets/images/team.png";
import Frame from "../../assets/images/Frame 441097.png";
import { LuDot } from "react-icons/lu";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import AliceCarousel from "react-alice-carousel";
import { FaTwitterSquare } from "react-icons/fa";
import "react-alice-carousel/lib/alice-carousel.css";
export const DataDeletion = () => {
  
  return (
    <Box h="auto">
      <Flex
        px={{ base: "10", md: "40" }}
        bg="#EBEBEB"
        mt="16"
        gap="5"
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Text
          align={"center"}
          w="fit-content"
          color="#BF0D0D"
          px="5"
          textTransform={"uppercase"}
          fontSize={"sm"}
          border={"1px solid #BF0D0D"}
          bg="white"
        >
          User Data Deletion
        </Text>

        <Flex
          gap="5"
          flexDirection={"column"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {/* <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={"medium"}>
            At Flashride...
          </Text> */}
          <Text maxW={"lg"}>Delete my Flashride data</Text>
        </Flex>
        <Image src={AboutSvg} />
      </Flex>
      <Box
        py="20"
        px={{ base: "10", md: "40" }}
        textAlign={"center"}
        bg="white"
      >
       
        <VStack spacing={"10"}>
          <Text maxW="620px" textAlign={"center"}>
            At Flashride we processes some of your personal data. When you install and sign
            up in the Flashride app, you agree with our General Terms and Conditions
            and give your consent for collecting and processing your data. Your
            personal data will be stored as long as you have an active account.
            To request a transfer of your personal data, please contact our
            Support team via the app.
          </Text>
          <Text maxW="620px" textAlign={"center"}>
            To delete your data, please send a request for your account to be
            deleted via an in-app message. Afterwards, your personal data will
            be deleted from the databases, unless such data is required for
            accounting, dispute resolution, or fraud prevention purposes. For
            more information, please visit our Privacy Policy.
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};
