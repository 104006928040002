import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import {AiFillFacebook} from "react-icons/ai"
import {FaInstagramSquare, FaLinkedin, FaTwitterSquare} from "react-icons/fa"
import {HiOutlineArrowLongLeft} from "react-icons/hi2"
import { Link } from 'react-router-dom'
export const Success = () => {
    return (
        <Flex justifyContent={'center'} alignItems={'center'} px='5' py='20'>
            <Box textAlign={'center'}>
                <Text fontSize={{ base: '4xl', md: '5xl' }}>Thank you for contacting us</Text>
                <Text fontSize={{ base: 'sm', md: 'auto' }}>Your message has been received, and we will be in touch with you shortly.</Text>
                <Link to='/'>
                <Button _hover={{bg:'white',color:'#BF0D0D',border:'1px solid #BF0D0D'}}  mt='10' px='10' leftIcon={<HiOutlineArrowLongLeft size={24}/>} bg='#BF0D0D' color='white'>Back to home</Button>
                </Link>
                
            </Box>
        </Flex>
    )
}
