import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Hero } from "./pages/hero-screen/Hero";
import NavbarComponent from "./components/navbar";
import { About } from "./pages/about-us/About";
import { Footer } from "./pages/footer/Footer";
import { MainScreen } from "./pages";
import { AboutUs } from "./pages/about-us-screen/AboutUs";
import { Faq } from "./pages/faq-screen/Faq";
import { Contact } from "./pages/contact-screen/Contact";
import { Success } from "./components/SuccessScreen";
import TermsofService from "./pages/terms-of-service/TermsofService";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import { DataDeletion } from "./pages/data-deletion/DataDeletion";
function App() {

  return (
    <Router>
      <Box bg="#EBEBEB">
        <NavbarComponent />
        <Box>
          <Routes>
            <Route path="/contact" element={<Contact />} />
            <Route path="/success-screen" element={<Success />} />
            <Route path="/" element={<MainScreen />} />
            <Route path="/home" element={<MainScreen />} />
            <Route path="/hero" element={<Hero />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/support" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsofService />} />
            <Route path="/data-deletion" element={<DataDeletion />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
