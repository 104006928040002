import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import EconomyCar from "../../assets/images/Economy.png"
import ComfortCar from "../../assets/images/Comfort.png"
import ExecutiveCar from "../../assets/images/Exclusive.png"

export const Vehicles = () => {
    const Cards = ({ Car, Heading, Description, backgroundColor }) => {
        return (
            <Box px='10' color='white' borderRadius={'8px'} border='1px solid black' pt='5' bg={backgroundColor} w={{base:'fit-content',md:'80'}} h='96' position={'relative'}>
                <Text fontWeight={'medium'} fontSize={'28'}>{Heading}</Text>
                <Text mt='5'>{Description}</Text>
                <Box bottom={'0'} position={'absolute'}><Image src={Car} /></Box>
            </Box>
        )
    }
    return (
        <Box py='20' bg='#FAFAFA' px={{base:'10',md:'40'}}>
            <Text  fontSize={'32'} fontWeight={'medium'} textAlign={'center'}>Vehicles</Text>
            <Flex gap={{base:'10',md:'0'}} flexDirection={{base:'column',md:'row'}} mt='10' alignItems={'center'} justifyContent={'space-between'}>

            <Cards backgroundColor={"#5BBF0D"} Heading={'Economy'} Description={'Pefade syrade jårad om karede. Åmisk ultrahode langar.'} Car={EconomyCar} />
            <Cards backgroundColor={"#BF0D0D"} Heading={'Comfort'} Description={'Pefade syrade jårad om karede. Åmisk ultrahode langar.'} Car={ComfortCar} />
            <Cards backgroundColor={"#BF660D"} Heading={'Executive'} Description={'Pefade syrade jårad om karede. Åmisk ultrahode langar.'} Car={ExecutiveCar} />
            </Flex>
        </Box>
    )
}
