import React from 'react'
import { Hero } from './hero-screen/Hero'
import { About } from './about-us/About'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { Footer } from './footer/Footer'
import { Vehicles } from './vehicles/Vehicles'
import { Payment } from './payment/Payment'
import { CallToAction } from './call-to-action/CallToAction'

export const MainScreen = () => {
  return (
    <Box>
      <Box>
        <Box top='610px' pos={'absolute'} w='full' >
          <Flex display={{base:'none',md:'flex'}} justifyContent={'center'} gap='16' py='5' px='10' alignItems={'center'} borderRadius={'5'} bg='white' h='32' border='1px solid #434343' zIndex={'40'} my='10' mx='60'>
            <Box textAlign={'left'} position={'relative'}>
              <Text w='24' position={'absolute'} top='-1' color='#B6B4B4' py='0' fontSize={'sm'}>Active users</Text>
              <Text fontWeight={'medium'} color='#434343' py='0' fontSize={'5xl'}>250+</Text>
            </Box>
            <Divider orientation='vertical' />
            <Box textAlign={'left'} position={'relative'}>
              <Text w='28' position={'absolute'} top='-1' color='#B6B4B4' py='0' fontSize={'sm'}>Completed trips</Text>
              <Text fontWeight={'medium'} color='#434343' py='0' fontSize={'5xl'}>3000+</Text>
            </Box>
            <Divider orientation='vertical' />
            <Box textAlign={'left'} position={'relative'}>
              <Text  w='24' position={'absolute'} top='-1' color='#B6B4B4' py='0' fontSize={'sm'}>Regions</Text>
              <Text fontWeight={'medium'} color='#434343' py='0' fontSize={'5xl'}>3+</Text>
            </Box>
          </Flex>
        </Box>
        <Hero />
        <About />
      </Box>
      <Vehicles/>
      <Payment/>
      <CallToAction/>
      {/* <WhyChooseUs /> */}
     
    </Box>
  )
}

