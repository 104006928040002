import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"
import { ColoredSentence } from "../../components/ColoredSentence"
import PhoneMarkup from "../../assets/images/main.png"
import { BiRightArrowAlt } from "react-icons/bi"

export const Hero = () => {
  return (
    <Box  px={{ base: '10', md: '32' }} mt={{base:'0',md:'10'}} >
      
      <Flex gap='10' flexDirection={{base:'column',md:'row'}} justifyContent={'space-between'} overflow={'hidden'}>

        <Box mt={{base:'0',md:'20'}} >

        <Text fontSize={{base:'32',md:'52'}} display={{base:'none',md:'block'}} fontWeight={'medium'}>Get On the Go  with <br></br> us at<span style={{ color: '#BF0D0D' }}> Flashride</span></Text>
          <Text fontSize={{base:'36',md:'52'}} display={{base:'auto',md:'none'}} fontWeight={'medium'}>Get On the Go  with  us at <br></br><span style={{ color: '#BF0D0D' }}> Flashride</span></Text>

          <Text fontSize={'sm'} color='#1E1E1E'>We are your best bet at having a comfortable and more efficient movement</Text>
          <Button borderRadius={'5 '} rightIcon={<BiRightArrowAlt size='24' />} mt='7' px='10' fontSize={'sm'} color='white' bg='#BF0D0D'>Get the app</Button>
        </Box>
        <Image  src={PhoneMarkup} />
      </Flex>

    
    </Box>
  )
}
