import { Box, Button, Flex, FormControl, FormLabel, Input, SimpleGrid, Text, Textarea } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { MdEmail } from "react-icons/md";
import { FaFreeCodeCamp, FaLinkedin, FaWhatsapp,  } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoWhatsapp } from "react-icons/io";
import { SiHashnode } from "react-icons/si";
export const Contact = () => {
    return (

        <Box h='auto' px={{ base: '5', md: '20' }} position='relative'>
            {/* <Flex top='32' left='5' position={'absolute'} gap='6' pr='5' w='fit-content' borderRight={'1px solid black'} flexDirection={'column'}>
                <IoLogoWhatsapp size={'20'} />
                <RiInstagramFill size={'20'} />
                <FaLinkedin size={'20'} />
                <FaFreeCodeCamp size={'20'} />
                <SiHashnode size={'20'} />
                <FaDev size={'20'} />
            </Flex> */}
            <Box pt='10' textAlign={'center'}>

                <Text fontSize={{ base: '4xl', md: '5xl' }} fontWeight={'medium'}>Get in touch with our team</Text>
                <Text>Please make contact, and we will respond within 24 hours.</Text>
            </Box>
            <Flex  alignItems={'center'} justifyContent={'center'} py='3'>
               <MdEmail size={'24'}/>
               <Text borderBottom={'1px solid black'} fontSize={'sm'} fontWeight={'medium'}>: support@flashride.ng</Text>
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'center'} pb='10'>
                <FormControl px={{ base: '5', md: '72' }} w='full'>
                    <SimpleGrid rowGap={'5'} columnGap={'10'} columns={{ base: '1', md: '2' }}>
                        <Box>
                            <FormLabel color={"#344054"} fontSize={'16px'} >First Name</FormLabel>
                            <Input fontSize={'14px'} border='1px solid #D0D5DD' placeholder='First Name' />
                        </Box>
                        <Box>
                            <FormLabel color={"#344054"} fontSize={'16px'}>Last Name</FormLabel>
                            <Input fontSize={'14px'} border='1px solid #D0D5DD' placeholder='Last Name' />
                        </Box>
                        <Box>
                            <FormLabel color={"#344054"} fontSize={'16px'}>Email</FormLabel>
                            <Input fontSize={'14px'} border='1px solid #D0D5DD' placeholder='Email' />
                        </Box>
                        <Box>
                            <FormLabel color={"#344054"} fontSize={'16px'}>Phone Number</FormLabel>
                            <Input fontSize={'14px'} border='1px solid #D0D5DD' placeholder='Phone Number' />
                        </Box>
                    </SimpleGrid>
                    <Box>
                        <FormLabel color={"#344054"} fontSize={'16px'}>Message</FormLabel>
                        <Textarea fontSize={'14px'} border='1px solid #D0D5DD' placeholder='Message' />
                    </Box>
                    <Link to='/success-screen'>
                        <Button _hover={{ bg: '#BF0D0D', color: 'white' }} bg='#BF0D0D' color='white' mt='10' w='full'>Send</Button>
                    </Link>
                </FormControl>
            </Flex>
        </Box>
    )
}
