'use client'

import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import Logo from "../assets/image 3.png"


const Links = ['Home', 'About', 'Blog', 'FAQs', 'Contact']

const NavLink = (props) => {
  const { children } = props

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      fontSize='sm'
      fontWeight='medium'
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      color='gray'
      href={`/${children}`}>
      {children}
    </Box>
  )
}

export default function NavbarComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box py='5' zIndex={'-1'} px={{ base: '2', md: '32' }}>
        <Flex h={16} bg={{ base: 'none', md: 'white' }} w='full' alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack px='10' w='full' justifyContent={'space-between'} alignItems={'center'}>
            <Flex gap='4' alignItems={'center'}>
              <Image h='10' src={Logo} />
              <Text display={{ base: 'none', md: 'auto' }} color={'#3E3E3E'} fontWeight={'medium'}>Flashride</Text>
            </Flex>
            <Flex alignItems={'center'} gap='4'>

              <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                {Links.map((link) => (
                  <NavLink key={link}>{link}</NavLink>
                ))}
              </HStack>
              <Button display={{base:'none',md:'block'}} borderRadius={'5 '} px='10' fontSize={'sm'} color='white' bg='#BF0D0D'>Book a ride</Button>
            </Flex>
          </HStack>
          {/* <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
              </MenuButton>
              <MenuList>
                <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider />
                <MenuItem>Link 3</MenuItem>
              </MenuList>
            </Menu>
          </Flex> */}
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
              <Button borderRadius={'5 '} px='10' fontSize={'sm'} color='white' bg='#BF0D0D'>Book a ride</Button>
            </Stack>
          </Box>
        ) : null}
      </Box>


    </>
  )
}