import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import Credit from "../../assets/images/credit.png"
import Cash from "../../assets/images/cash.png"
import Wallet from "../../assets/images/wallet.png"
export const Payment = () => {
  return (
    <Box bg='#FAFAFA' px={{base:'10',md:'40'}} py='10'>
      <Text textAlign={'center'} color='gray.400' fontSize={'20'} fontWeight={'medium'} mb='5'>Payment</Text>
      <Flex flexDirection={{base:'column',md:'row'}} gap='16px' alignItems={'flex-start'} p='5' borderRadius={'8px'} border='1px solid #434343'>
        <Flex gap='3' flex={'0 0 1'} h='fit-content' py='2' justifyContent={'center'} alignItems={'center'} w={{base:'full',md:'80'}} borderRadius={'4px'} bg='linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #BF660D'>
          <Image src={Credit}/>
          <Text fontWeight={'medium'} color='#BF660D'>Credit/Debit Card</Text>
        </Flex>

        <Flex gap='3' flex={'0 0 1'} h='fit-content' py='2' justifyContent={'center'} alignItems={'center'} w={{base:'full',md:'80'}} borderRadius={'4px'} bg='var(--primary-primary-09, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #BF0D0D)'>
          <Image src={Wallet}/>
          <Text fontWeight={'medium'} color='#BF660D'>Wallet</Text>
        </Flex>

        <Flex gap='3' flex={'0 0 1'} h='fit-content' py='2' justifyContent={'center'} alignItems={'center'} w={{base:'full',md:'80'}} borderRadius={'4px'} bg='linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #5BBF0D;'>
          <Image src={Cash}/>
          <Text fontWeight={'medium'} color='#5BBF0D'>Cash</Text>
        </Flex>
      </Flex>
    </Box>
  )
}
