import React from 'react'
import Phone1 from "../../assets/Ride order.png"
import Phone2 from "../../assets/Group-1.png"
import Phone3 from "../../assets/Group.png"
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { BiLogoPlayStore, BiRightArrowAlt } from "react-icons/bi";
import { FaApple } from "react-icons/fa";
import AboutUs from "../../assets/images/About Us.png"
import GirlCustomer from "../../assets/images/girl.png"
import Driver from "../../assets/images/boy.png"
export const About = () => {
  return (
    <Box p={{base:'10',md:'40'}} bg='white' w='full' h={{ base: 'auto', md: 'auto' }}>
      <Flex flexDirection={{base:'column-reverse',md:'row'}} borderRadius={'12px'} justifyContent={'space-between'} bg='#FFFAF9' py={{base:'10',md:'20'}} px={{base:'5',md:'10'}}>
        <Box>
          <Image h='40'  src={AboutUs} />
        </Box>
        <Flex flexDirection={'column'} gap={'5'}>
          <Text fontSize={{base:'36',md:'52'}} fontWeight={'medium'}>About Us</Text>
          <Text maxW={'96'}>Flashride is a taxi/cab booking app. Founded march 28, 1957 by Solomon Akanbi and Co.
            At Flashride, we’ve been able to carve our niche around effectively meeting the daily transport need of our customers. Providing them luxury at a pocket friendly price.</Text>
          <Button as={'a'} href='/About' borderRadius={'5 '} rightIcon={<BiRightArrowAlt size='24' />} mt='7' w='fit-content' px='10' fontSize={'sm'} color='white' bg='#BF0D0D'>Read more</Button>
        </Flex>
      </Flex>

      <Flex flexDirection={'column'} gap={{base:'6',md:'32'}} mt='20'>
        <Flex flexDirection={{base:'column',md:'row'}} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            <Text fontSize={{base:'4xl',md:'6xl'}} fontWeight={'medium'}>Incredible Customer Service</Text>
            <Text fontSize={{base:'sm',md:'lg'}} maxW='md'>We are your best bet at having a comfortable and more efficient movement</Text>
          </Box>
          <Box>
            <Image h='96' objectFit={{base:'contain',md:'contain'}} src={GirlCustomer} />
          </Box>
        </Flex>
        <Flex  flexDirection={{base:'column-reverse',md:'row'}} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            <Image h='96' objectFit={{base:'contain',md:'contain'}} src={Driver} />
          </Box>
          <Box>
            <Text fontSize={{base:'4xl',md:'6xl'}} fontWeight={'medium'}>Professional <br></br> Drivers</Text>
            <Text fontSize={{base:'sm',md:'lg'}} maxW='md'>We are your best bet at having a comfortable and more efficient movement</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}
