import { Box, Button, Flex, Image, Input, Text } from '@chakra-ui/react'
import React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'

import Frame from "../../assets/images/Frame.png"
import Referral from "../../assets/images/Referral.png"
export const CallToAction = () => {
    return (
        <Box bg='#FAFAFA' px={{base:'10',md:'40'}} py='10'>
            <Flex alignItems={'center'} flexDirection={'column'}>

                <Text textAlign={'center'} fontSize={'4xl'} fontWeight={'medium'} >Get a free ride anywhere<br></br> when you sign up</Text>
                <Flex my='5' alignItems={'center'} flexDirection={'column'} borderTopRadius={'full'} position={'relative'} bg='#EDEDED' h='sm' w={{base:'full',md:'3xl'}}>
                    <Button top='-10' position={'absolute'} w='fit-content' borderRadius={'5 '} rightIcon={<BiRightArrowAlt size='24' />} mt='7' px='10' fontSize={'sm'} color='white' bg='#BF0D0D'>Get Started</Button>
                    <Image bottom={'0'} position={'absolute'} h='72' src={Frame} />
                </Flex>

                <Box bg='#F9DCD9' mt='20' w='full' borderRadius={'12px'}>
                    <Flex flexDirection={{base:'column-reverse',md:'row'}} p='10' justifyContent={'space-between'}>
                        <Box>
                            <Text fontSize={'2xl'} fontWeight={'medium'} maxW={'md'}>Subscribe to our newsletter to receive updates</Text>
                            <Flex mt='10' p='1' borderRadius={'6px'} bg='white' border='1px solid #434343'>
                                <Input border='none' placeholder='Email address'/>
                                <Button px='10' color='white' bg='#BF0D0D'>Subscribe</Button>
                            </Flex>
                        </Box>
                        <Image src={Referral}/>
                    </Flex>
                </Box>

            </Flex>
        </Box>
    )
}
