import React from "react";
import { Box, Button, Flex, Image, Spacer, Text,} from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { Link } from "react-router-dom"; 

export const PrivacyPolicy = () => {
  return (
    <Box h="auto">
      <Flex
        px={{ base: "10", md: "40" }}
        bg="#EBEBEB"
        mt="16"
        gap="5"
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Text
          align={"center"}
          w="fit-content"
          color="#BF0D0D"
          px="5"
          textTransform={"uppercase"}
          fontSize={"sm"}
          border={"1px solid #BF0D0D"}
          bg="white"
        >
          Privacy Policy
        </Text>

        <Flex
          gap="5"
          flexDirection={"column"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {/* <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={"medium"}>
            Flashride's Privacy Policy...
          </Text> */}
          <Text maxW={"xxl"}>
            Our privacy notice lets you know how we promise to look after your
            personal data. This notice tells you things like what information we
            use to provide you with our bundle of transportation services, how
            we use your information to keep you safe, and the choices and
            controls available to you. This notice also tells you about your
            privacy rights and how data protection rules work to protect
            everyone.
          </Text>
        </Flex>
        <Box mt="10">
          <Accordion>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Personal data we process
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={1}>
                We only process information that we need, so we can provide you
                with the best service on our transportation marketplace.
                <UnorderedList p={6}>
                  <ListItem>
                    Contact details: things like name, phone number, and e-mail
                    address. For some of our services, we might require a little
                    more, like your home address.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Profile information: things like your profile picture, saved
                    addresses, language, and communication preferences.The app
                    does not use thirdparty services that may collect
                    information used to identify you. link to the privacy policy
                    of third-party service providers used by the app
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Link to the privacy policy of thirdparty service providers
                    used by the app{" "}
                    <Link to="https://policies.google.com/privacy">
                      <Text color={"#bf0d0d"}>.Google Play Services</Text>
                    </Link>
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Geolocation: such as where you need a ride from, or the
                    location of nearby scooters to you, the time, the journey
                    progression, and the final destination of your trip.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Payment information like the amount charged and the payment
                    card used.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Communication and correspondence records such as when you
                    engage with our in-app chat, or speak with our customer
                    service agents.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Limited identification data of the device, like the IP
                    address, on which the Flashride app has been installed.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Data about the usage of the transportation services: things
                    like data about journey status, times, and data about your
                    conduct as assessed by drivers.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Purpose of the processing
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                We process your personal data so we can provide you with one or
                more of our transportation services:
                <UnorderedList p={6}>
                  <ListItem>
                    We connect you with a driver: We collect and process personal data for the
                    purpose of connecting passengers with drivers so you can be
                    picked up and dropped off; or to show you where our nearest
                    available hire-vehicles are located, such as our cars.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We get you to where you want to go: We display geolocation
                    data and the phone number of passengers to drivers when
                    ride-hailing to enable efficient pick-up and drop-off, and
                    make the journey and route transparent. This data is
                    collected only when the Flashride app is activated. The
                    collection of geolocation data stops after closing the
                    Flashride app.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We make sure your journey goes smoothly: We use geolocation
                    data to make sure you get to your destination and resolve
                    quality issues related to our services. We also need to know
                    where you choose to drop off a hire-vehicle so we can bill
                    correctly, recharge batteries and maintain our fleet.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We make sure the app is optimal: We use contact details to
                    notify passengers and riders of updates to the Flashride app
                    so you can keep using our services. We also gather limited
                    data from the device that you use to connect you with our
                    internet, mobile, and telephone services, and to help keep
                    your account safe through authentication and verification
                    checks.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We make sure your ride is the quickest, cheapest and most
                    convenient to you: We collect data on the routes taken by
                    our passengers and riders to analyze geographic coverage.
                    This lets us do things like improve recommendations to the
                    drivers about the most efficient routes, and helps us make
                    sure that our drivers, are in the
                    most convenient locations for customers.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We collect your payment: We obtain payment details to
                    process passenger payment on behalf of drivers for
                    ride-hailing. And we process payments for our own
                    drivers.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We maintain and promote standards: We collect data about
                    journey statuses, time, and passenger ratings from driver
                    feedback to encourage user safety, promote compliance with
                    our terms and conditions, and make sure we’re providing a
                    quality and enjoyable service to everyone. Customer support
                    data and correspondence is collected for the purposes of
                    feedback, and resolving disputes and service quality issues.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We keep you informed: Your name, phone number, and email
                    address will be used to communicate with you for things like
                    letting you know that your journey has been completed,
                    sending you trip confirmation slips and receipts, and
                    letting you know about important service updates.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Legal Basis
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                We process your personal data for various purposes and rely on
                different legal bases:
                <UnorderedList p={6}>
                  <ListItem>
                    Personal data is generally processed to provide the services
                    contracted through the Flashride app. This includes
                    processing personal data to meet our obligations under the
                    terms and conditions.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    In other circumstances, we process personal data relying on
                    legitimate interest grounds. Legitimate interests include
                    providing an innovative, personalized, safe, and profitable
                    service. This may also include investigating and detecting
                    fraudulent payments, maintaining network and system
                    security, and responding to suspected or actual criminal
                    acts.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We may rely on alternative legal bases when necessary to
                    comply with a legal obligation, protect vital interests, or
                    when you have given us clear consent for another specific
                    purpose.
                  </ListItem>
                  <Spacer h={4} />
                </UnorderedList>
                If you choose not to give us personal information, it may:
                <UnorderedList p={6}>
                  <ListItem>
                    Prevent us from fulfilling the contract with you or stop us
                    from meeting legal obligations, potentially leading to the
                    suspension or blocking of your account.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    For example, refusing to share geolocation data via the App
                    may prevent us from directing a driver to your location for
                    pick-up or showing you one of our own vehicles close to you
                    for hire.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Refusal to undertake an identity verification check to
                    ensure the integrity of your account may result in the
                    suspension or blocking of the account to prevent fraud.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Recipients of Personal Data
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                We handle the sharing of personal data with care and
                transparency:
                <UnorderedList p={6}>
                  <ListItem>
                    Sharing is limited to what is required, and we do not sell
                    your personal information.
                  </ListItem>
                </UnorderedList>
                Specific sharing details include:
                <UnorderedList p={6}>
                  <ListItem>
                    The personal data of the passenger is disclosed to
                    our drivers only when they engage with a ride on
                    the Flashride app. In such cases, the driver will see the
                    name, phone number, and
                    geolocation data of the passenger.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    After providing the transportation service, the name and
                    telephone number of the
                    ride-hailing passenger will remain visible to the driver for
                    24-48 hours to facilitate the resolution of any issues.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Feedback given by passengers regarding the quality of the
                    ride-hailing service is anonymous. Your rating is private
                    between you and Flashride.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    In some circumstances, we are legally obliged to share
                    information with external recipients, such as under a Court
                    Order or when cooperating with a data protection supervisory
                    authority in handling complaints or investigations.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    We can respond to requests from law enforcement agencies
                    when required by law, furthers a public interest task,
                    affects users in that jurisdiction, and is consistent with
                    internationally recognized standards.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Your Rights and Controls
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                We want you to stay in control of your personal data. Flashride
                provides you with controls through the app where you can view
                your personal information, including your profile data and trip
                history.
                <UnorderedList p={6}>
                  <ListItem>
                    <strong>Your Right of Access:</strong> You can access your
                    personal data via the Flashride app. You have the right to ask us
                    for copies of your personal information, with some
                    exemptions.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    <strong>Your Right to Rectification:</strong> You can access
                    and update your personal data via the Flashride app. You have the
                    right to ask us to rectify information you think is
                    inaccurate and to complete information you think is
                    incomplete.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    <strong>Your Right to Erasure:</strong> You have the right
                    to ask us to erase your personal information in certain
                    circumstances.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    <strong>Your Right to Restriction of Processing:</strong>{" "}
                    You have the right to ask us to restrict the processing of
                    your information in certain circumstances. This means that
                    your data can only be used for certain things, such as legal
                    claims or to exercise legal rights.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    <strong>Your Right to Object to Processing:</strong> You may
                    have the right to object to processing if we are processing
                    your information on the basis of legitimate interests. You
                    may submit an objection to any automated decision we have
                    made and ask that a person reviews it.
                  </ListItem>
                  <Spacer h={4} />
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Flex>
    </Box>
  );
};

export default PrivacyPolicy;
