import React from 'react'
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import {
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

export const TermsofService = () => {
  return (
    <Box h="auto">
      <Flex
        px={{ base: "10", md: "40" }}
        bg="#EBEBEB"
        mt="16"
        gap="5"
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Text
          align={"center"}
          w="fit-content"
          color="#BF0D0D"
          px="5"
          textTransform={"uppercase"}
          fontSize={"sm"}
          border={"1px solid #BF0D0D"}
          bg="white"
        >
          Terms of Service
        </Text>

        <Flex
          gap="5"
          flexDirection={"column"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {/* <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={"medium"}>
            Flashride's Privacy Policy...
          </Text> */}
          <Text maxW={"xxl"}>
            These General Terms and Conditions set out the terms and conditions
            applying to and governing the usage of the Flashride app - technology
            which connects passengers with drivers to help them move around
            cities more efficiently.
          </Text>
        </Flex>
        <Box mt="10">
          <Accordion>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Terms and Conditions for Passengers
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={1}>
                Using the Flashride app
                <UnorderedList p={6}>
                  <ListItem>
                    Flashride provide transport services. This services are provided
                    by our drivers under a contract with us for the carriage of
                    passengers. We provide transport services on an
                    independent basis as economic and professional service providers. Disputes
                    arising from consumer rights, legal obligations, or from law
                    applicable to the provision of transport services will be
                    resolved by us. Data regarding
                    the drivers and their transport service is available in the
                    Flashride app, and receipts for journeys are sent to the email
                    address listed in the passenger's profile.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    When using the Flashride app, you can choose whether to pay the
                    our driver for the transport service or use Flashride in-App Payment.
                    Charges will be inclusive
                    of applicable taxes where required by law. Charges may
                    include other applicable fees, tolls, and/or surcharges,
                    including a booking fee, municipal tolls, airport
                    surcharges, or processing fees for split payments.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    During the installation of the Flashride app, the passenger's
                    mobile number is linked to the respective Flashride user account
                    and added to our database. If you are no longer using your
                    mobile number, you must notify Flashride within 7 days so we can
                    anonymize your account data. If you do not notify us about
                    any change to your number, your mobile operator can assign
                    the same mobile number to the next person, and when using
                    the Flashride app, this new person can see your data.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Promotional Codes
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                We process your personal data so we can provide you with one or
                more of our transportation services:
                <UnorderedList p={6}>
                  <ListItem>
                    Flashride may send you promotional codes on a per promotion
                    basis. Promotional code credit can be applied towards
                    payment on completion of a ride or other features or
                    benefits related to the service and/or a Third Party's
                    service and are subject to any additional terms that are
                    established on a per promotional code basis. Expiration
                    dates of promo codes will be reflected in-app once you have
                    applied the promo code to your account.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    If your trip amount exceeds the redeemable credit allocated
                    to your ride, the balance will be automatically deducted
                    from the flashride wallet. Similarly, a promotional
                    code credit only applies on a per ride basis and cannot
                    carry over to a next ride/ trip and therefore will be
                    forfeited. Only one promotional code may be applied per
                    trip.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Flashride reserves the right to cancel any promotional code at
                    any time for any reason. This includes, but is not limited
                    to, if Flashride deems that codes are being used in an unlawful
                    or fraudulent manner, those issued mistakenly, and those
                    which have expired.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Flashride in-App Payment
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <UnorderedList p={6}>
                  <ListItem>
                    You can pay for the transport services with a card, mobile
                    carrier billing and other payment methods (e.g: Flashride
                    Business), which require prior activation in the Flashride app.
                    By providing Flashride in-App Payment service, Your obligation to the driver
                    service will be fulfilled when the payment order is given to
                    transfer funds to Flashrides' bank account. You, as a passenger
                    are responsible for ensuring that the payment takes place
                    and ensuring that sufficient funds are available.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    When making payments by Flashride in-App Payment, Flashride receives
                    your payments and forwards money to the driver. Flashride may ask
                    additional data from you to verify payment method.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    When making payments by Flashride in-App Payment for transport
                    services, Flashride is not responsible for possible third-party
                    payment costs (mobile operators, bank fees etc.). These
                    service providers may charge you additional fees when
                    processing payments in connection with the Flashride in-App
                    Payment. Flashride is not responsible for any such fees and
                    disclaims all liability in this regard. Your payment method
                    may also be subject to additional terms and conditions
                    imposed by the applicable third-party payment service
                    provider; please review these terms and conditions before
                    using your payment method.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Flashride will be responsible for the functioning of Flashride in-App
                    Payment and provide support in resolving problems. The
                    resolution of disputes related to Flashride in-App Payment also
                    takes place through us. For payment support service please
                    contact: info@flashride.ng.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: "2xl", md: "xl" }}
                    fontWeight={"medium"}
                  >
                    Ordering and cancelling transport services{" "}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                If you order a transport service and the driver has agreed to
                undertake the work then the transport service is considered to
                be ordered.
                <UnorderedList p={6}>
                  <ListItem>
                    Once a driver confirms that he/she will complete your
                    journey, you will enter into a separate agreement with the
                    driver for the provision of the journey on such terms and
                    conditions as you agree with the driver. Flashride does not
                    provide journeys and is not a party to your agreement with
                    the relevant driver.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Cancelling the use of an ordered transport service is
                    considered to be the situation where the driver has replied
                    to your request and you subsequently reject, cancel or
                    refuse the transport service. When a transport service
                    request is cancelled after certain time period you are
                    required to pay a cancellation fee.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    If you cancel a transport service request on multiple
                    successive instances within 24-hour we may temporarily block
                    your account for warning. After multiple such warnings, we
                    may suspend your account for longer period (e.g: 6 month).
                    After that period you could ask to reactivate your account
                    and your application will be reviewed by Flashride.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    When driver notifies the passenger about the arrival of the
                    vehicle to its destination and passenger or people for whom
                    the transport was ordered do not arrive at the vehicle
                    within certain time period as specified in the Flashride app, the
                    request will be deemed cancelled. Sometimes driver may
                    decide to cancel your request, please note that Flashride is not
                    responsible for such situations.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    Once the driver arrives and sends you a notification that
                    he/she has arrived the Flashride app may begin charging fare on a
                    waiting time basis according to the rates specified in the
                    Flashride app.
                  </ListItem>
                  <Spacer h={4} />
                  <ListItem>
                    If you have requested transport services using the Flashride app
                    and cause damage to the driver's vehicle or its furnishing
                    (among else, by blemishing or staining the vehicle or
                    causing the vehicle to stink), the driver will have the
                    right to require you to pay a penalty fee and require
                    compensation for any damages exceeding the penalty. If you
                    do not pay the penalty and/or compensate the damage, Flashride
                    may pursue the claims on behalf of the driver.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>
           
           
          </Accordion>
        </Box>
      </Flex>
    </Box>
  );
};

export default TermsofService;