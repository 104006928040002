import { Box, Button, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import AboutSvg from '../../assets/images/About Us.png'
import Mission from "../../assets/images/Frame 441063.png"
import Team from "../../assets/images/team.png"
import Frame from "../../assets/images/Frame 441097.png"
import { LuDot } from "react-icons/lu";
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import AliceCarousel from 'react-alice-carousel';
import { FaTwitterSquare } from "react-icons/fa";
import 'react-alice-carousel/lib/alice-carousel.css';
export const AboutUs = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };
    const Items = [
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,
        <Box position={'relative'}>
            <Image objectFit={'cover'} borderRadius={'8'} h='44' w='52' src={Team} />
            <Flex w='full' justifyContent={'center'} alignItems={'center'}>

                <Flex py='1' px='3' justifyContent={'space-between'} alignItems={'center'} borderRadius={'8'} bottom={'3'} left={'7%'} position={'absolute'} h='fit-content' w='44' bg='white'>
                    <Box>
                        <Text fontSize={'sm'}>Solomon Akanbi</Text>
                        <Text fontSize={'xs'} color={'gray.300'}>Co-Founder</Text>
                    </Box>
                    <FaTwitterSquare size='32' color='#2696E7' />
                </Flex>
            </Flex>
        </Box >,


    ]
    return (
        <Box h='auto' >
            <Flex px={{ base: '10', md: '40' }} bg='#EBEBEB' mt='16' gap='5' alignItems={'center'} flexDirection={'column'} justifyContent={'center'}>


                <Text align={'center'} w='fit-content' color='#BF0D0D' px='5' textTransform={'uppercase'} fontSize={'sm'} border={'1px solid #BF0D0D'} bg='white'>About Flashride</Text>

                <Flex gap='5' flexDirection={'column'} justifyContent={'center'} textAlign={'center'}>
                    <Text fontSize={{ base: '4xl', md: '5xl' }} fontWeight={'medium'}>At Flashride...</Text>
                    <Text maxW={'lg'}>we’ve been able to carve our niche around effectively meeting the daily transport needs of our customers. We provide luxury at pocket friendly prices.</Text>
                </Flex>
                <Image src={AboutSvg} />
            </Flex>
            <Box py='20' px={{ base: '10', md: '40' }} textAlign={'center'} bg='white' >
                <Text fontSize={'5xl'} fontWeight={'medium'}>The Gist..</Text>
                <VStack spacing={'10'}>
                    <Text maxW='620px' textAlign={'center'}>Flashride is a Nigerian indigenous ride hailing service co-founded by <b>Akanbi Solomon</b> and <b>Kalu Ude</b> in 2021 to fill in the gap of unavailability of a comfortable medium of transportation in the locality they resided.</Text>
                    <Text maxW='620px' textAlign={'center'}>At Flashride We prioritize customer safety and satisfaction, not compromising our affordable rates. We currently have over 250 Active users and have successfully completed over 3,000 trips. We aim at being the leading ride hailing service in Africa by 2030.</Text>
                </VStack>
            </Box>
            <Box bg='white' py='20' px={{ base: '10', md: '40' }}>
                <Flex flexDirection={{ base: 'column', md: 'row' }} justifyContent={'center'} gap='10'>
                    <VStack position={'relative'} gap='5' px='10' alignItems={'center'} justifyContent={'center'} borderRadius={'8px'} h='64' w={{base:'full',md:'xs'}} bg='#BF0D0D'>

                        <Box position='absolute' left='4' top='4'>

                            <LuDot size='36' color='white' />
                        </Box>
                        <Box position='absolute' right='4' top='4'>

                            <LuDot size='36' color='white' />
                        </Box> <Box position='absolute' left='4' bottom='4'>

                            <LuDot size='36' color='white' />
                        </Box> <Box position='absolute' right='4' bottom='4'>

                            <LuDot size='36' color='white' />
                        </Box>
                        <Text color={'#BF0D0D'} fontSize={'sm'} borderRadius={'4'} px='3' w='fit-content' bg={'white'}>OUR MISSION</Text>
                        <Text textAlign={'center'} fontSize={'sm'} color='white'>
                            To offer best in class comfortable, affordable transportation services to our customers safely and efficiently.</Text>
                    </VStack>
                    {/* <Box borderRadius={'8px'} h='64' w='xs' border='1px solid #BF0D0D'> */}
                    <Image h='64' w='xs' src={Mission} />
                    {/* </Box> */}
                </Flex>
            </Box>
            <Box bg='white' px={{ base: '10', md: '32' }}>
                <Text mb='5' fontWeight={'medium'} fontSize={'4xl'}>Our team</Text>
                <Box display={{base:'none',md:'block'}}>

                    <AliceCarousel disableButtonsControls responsive={{
                        0: { items: 1 },
                        576: { items: 2 },
                        768: { items: 3 },
                        992: { items: 4 },
                        1200: { items: 5 }
                    }} mouseTracking items={Items} />

                </Box>
                <Box display={{md:'none'}}>

                    <AliceCarousel disableButtonsControls responsive={{
                        0: { items: 1 },


                    }} mouseTracking items={Items} />

                </Box>
                <Flex py='20' justifyContent={'center'} >

                    <Flex flexDirection={{base:'column',md:'row'}} alignItems={'center'} py='10' borderRadius={'12'}  gap='32' justifyContent={'center'} w='full' border={{base:'none',md:'1px solid #434343'}}>
                        <Flex flexDirection={'column'} gap='5'>
                            <Text fontSize={'4xl'} maxW='xs'>Join our team of young, and creative talents.</Text>
                            <Text fontSize={'sm'}>Want to make a dent in your world? click on the link below. </Text>
                            <Button w='fit-content' borderRadius={'5 '} px='10' fontSize={'sm'} color='white' bg='#BF0D0D'>Careers at Flashride</Button>
                        </Flex>
                        <Box>
                            <Image src={Frame} />
                        </Box>

                    </Flex>
                </Flex>
                <Box>
                    <Text fontWeight={'medium'} fontSize={'3xl'}>The values that holds us<br></br> true and to account.</Text>
                    <Flex flexDirection={{base:'column',md:'row'}} gap={{base:'10',md:'auto'}} py='10' justifyContent={'space-between'}>
                        <Box py='3' px='10' border='1px solid #ECECEC' borderRadius={'8'}>
                            <Flex gap='3' alignItems={'center'}>
                                <Box borderRadius={'4'} h='7' w='7' bg='#F9E7F0' border='1px solid #BF0D66'></Box>
                                <Text maxW='24' fontWeight={'medium'} fontSize={'md'}>Customer Focus</Text>
                            </Flex>
                            <Text mt='3' maxW='180px' fontSize={'sm'}>Want to make a dent in your world? click on the link below. </Text>
                        </Box>
                        <Box py='3' px='10' border='1px solid #ECECEC' borderRadius={'8'}>
                            <Flex gap='3' alignItems={'center'}>
                                <Box borderRadius={'4'} h='7' w='7' bg='#F5EDFF' border='1px solid #9747FF'></Box>
                                <Text maxW='24' fontWeight={'medium'} fontSize={'md'}>Integrity</Text>
                            </Flex>
                            <Text mt='3' maxW='180px' fontSize={'sm'}>Want to make a dent in your world? click on the link below. </Text>
                        </Box>
                        <Box py='3' px='10' border='1px solid #ECECEC' borderRadius={'8'}>
                            <Flex gap='3' alignItems={'center'}>
                                <Box borderRadius={'4'} h='7' w='7' bg='#F9F0E7' border='1px solid #BF660D'></Box>
                                <Text maxW='24' fontWeight={'medium'} fontSize={'md'}>Teamwork</Text>
                            </Flex>
                            <Text mt='3' maxW='180px' fontSize={'sm'}>Want to make a dent in your world? click on the link below. </Text>
                        </Box>
                        <Box py='3' px='10' border='1px solid #ECECEC' borderRadius={'8'}>
                            <Flex gap='3' alignItems={'center'}>
                                <Box borderRadius={'4'} h='7' w='7' bg='#EFF9E7' border='1px solid #5BBF0D'></Box>
                                <Text maxW='24' fontWeight={'medium'} fontSize={'md'}>Diversity</Text>
                            </Flex>
                            <Text mt='3' maxW='180px' fontSize={'sm'}>Want to make a dent in your world? click on the link below. </Text>
                        </Box>

                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}
